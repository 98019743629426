<template>
  <div class="admin">
    <div class="mianbao">
      <div class="breadcrumb">
        <span style="color: #fff"> 综合除治> </span>
        <span style="color: #fff"> 疫木列表> </span>
        <span style="color: #016ae0"> 查看结果 </span>
      </div>
      <a-button type="primary" @click="handleBack" icon="rollback"
        >返回</a-button
      >
    </div>
    <div class="content">
      <div class="title">疫木编号:{{ treeId }}</div>
      <div class="center">
        <div class="left">
          <div class="fenge">
            <div class="title2">分割库</div>
            <div class="img">
              <div class="huanyuan">
                <img
                  style="width: 200px; height: 140px"
                  src="../../assets/fuyuanqian.jpg"
                />
                <a-button
                  @click="
                    () => {
                      visible = true;
                    }
                  "
                  type="primary"
                  style="
                    margin-top: 20px;
                    margin-bottom: 20px;
                    margin-left: -20px;
                  "
                  >还原</a-button
                >
              </div>
                
              <div class="view">
                <viewer
                  :images="detail.afterImg"
                  style="
                    display: flex;
                    flex-wrap: wrap;
                    overflow-y: auto;
                    max-height: 320px;
                  "
                >
                  <img
                    style="
                      width: 200px;
                      height: 140px;
                      margin-right: 30px;
                      margin-bottom: 20px;
                    "
                    :src="api + '/' + item.imgPrefix + item.imgSrc"
                    v-for="(item, index) in detail.afterImg"
                    :key="index"
                  />
                </viewer>
              </div>
            </div>
          </div>
          <div class="fensui">
            <div class="title2">粉碎库</div>
            <div class="img">
              <viewer
                :images="detail.smashImg"
                style="
                  display: flex;
                  flex-wrap: wrap;
                  overflow-y: auto;
                  max-height: 320px;
                "
              >
                  <img
                  style="
                    width: 200px;
                    height: 140px;
                    margin-right: 30px;
                    margin-bottom: 20px;
                  "
                  :src="api + '/' + item.imgPrefix + item.imgSrc"
                  v-for="(item, index) in detail.smashImg"
                  :key="index"
                />
                <video
                  style="
                    width: 200px;
                    height: 140px;
                    margin-right: 30px;
                    margin-bottom: 20px;
                  "
                  :src="api + '/' + item.imgPrefix + item.imgSrc"
                  v-for="(item, index) in detail.smashVideo"
                  :key="index"
                  controls="controls"
                ></video>
              </viewer>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="title1">自动对比结果</div>
          <div class="list">
            <div class="shang" style="border-top: 1px solid #005ea1">
              <div class="label">熵</div>
              <div class="huan">
                <radial_indicator
                  v-if="form.shang"
                  :percentNum="form.shang"
                  speed="3"
                  size="60"
                  color="#0094ff"
                  @animationFinished="animationFinished"
                  backgroundColor="#fff"
                  content=""
                  icon="mail"
                />
              </div>
            </div>
            <div class="shang">
              <div class="label">面积</div>
              <div class="huan">
                <radial_indicator
                  v-if="form.mianji"
                  :percentNum="form.mianji"
                  speed="3"
                  size="60"
                  color="#0094ff"
                  @animationFinished="animationFinished"
                  backgroundColor="#fff"
                  content=""
                  icon="mail"
                />
              </div>
            </div>
            <div class="shang">
              <div class="label">能量</div>
              <div class="huan">
                <radial_indicator
                  v-if="form.nengliang"
                  :percentNum="form.nengliang"
                  speed="3"
                  size="60"
                  color="#0094ff"
                  @animationFinished="animationFinished"
                  backgroundColor="#fff"
                  content=""
                  icon="mail"
                />
              </div>
            </div>
            <div class="shang">
              <div class="label">惯性矩</div>
              <div class="huan">
                <radial_indicator
                  v-if="form.guanxing"
                  :percentNum="form.guanxing"
                  speed="3"
                  size="60"
                  color="#0094ff"
                  @animationFinished="animationFinished"
                  backgroundColor="#fff"
                  content=""
                  icon="mail"
                />
              </div>
            </div>
            <div class="shang">
              <div class="label">特征相似度</div>
              <div class="huan">
                <radial_indicator
                  v-if="form.tezheng"
                  :percentNum="form.tezheng"
                  speed="3"
                  size="60"
                  color="#0094ff"
                  @animationFinished="animationFinished"
                  backgroundColor="#fff"
                  content=""
                  icon="mail"
                />
              </div>
            </div>
            <div class="shang">
              <div class="label">对比结果</div>
              <div class="huan">无丢失</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a-modal
      title="还原动画"
      :visible="visible"
      :footer="null"
      @cancel="
        () => {
          visible = false;
        }
      "
    >
      <img src="../../assets/huanyuan.gif" style="width: 470px" />
    </a-modal>
  </div>
</template>

<script>
import radial_indicator from "./components/radial-indicator.vue";
import img from "../../assets/u530.png";
export default {
  data() {
    return {
      visible: false,
      treeId: "",
      form: {
        shang: null,
        mianji: null,
        nengliang: null,
        guanxing: null,
        tezheng: null,
      },
      detail: {},
    };
  },
  mounted() {
    this.handleDetail();
    this.form = {
      shang: parseFloat(this.getRandomNumberByRange()),
      mianji: parseFloat(this.getRandomNumberByRange()),
      nengliang: parseFloat(this.getRandomNumberByRange()),
      guanxing: parseFloat(this.getRandomNumberByRange()),
      tezheng: parseFloat(this.getRandomNumberByRange()),
    };
    // console.log(this.form)
  },
  components: {
    radial_indicator,
  },
  methods: {
    getRandomNumberByRange() {
      return (
        Math.floor(Math.random() * 10 + 90) +
        "." +
        Math.floor(Math.random() * 99)
      );
    },
    handleDetail() {
	  this.treeId = this.$route.query.treeId;
	  this.$axios(this.api + '/elimination/accurate', {
      		params: {
      			treeId: this.treeId
      		}
      	}).then(res => {
          let data = res.data
          this.detail = data
      	})
    },
    handleShow(show) {
      this.show = show;
    },
    animationFinished(e) {
      // console.log(e)
    },
    handleBack() {
      this.$router.push("/list");
    },
  },
};
</script>

<style scoped="scoped">
.admin {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  padding: 10px 40px 20px 40px;
  background: url(../../assets/bg01.jpg) no-repeat;
  background-size: 100% 100%;
}
.huanyuan {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  margin-right: 30px;
}
.mianbao {
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.content {
  width: 100%;
  height: calc(100% - 60px);
  border: 1px solid #016ae0;
  margin-top: 10px;
  padding: 20px;
  color: #ffffff;
}
.title {
  width: 100%;
  color: #fff;
  text-align: left;
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px dashed #016ae0;
}
.center {
  width: 100%;
  height: calc(100% - 40px);

  /* background-color: yellow; */
  display: flex;
}
.left {
  width: calc(100% - 460px);
  height: 100%;
  margin-right: 30px;
  padding: 20px 0;
  /* background-color: red; */
  /* overflow-y: auto; */
}
.fenge .title2,
.fensui .title2 {
  width: 100%;
  height: 2em;
  font-size: 14px;
  text-align: left;
}
.right {
  width: 430px;
  height: 100%;
  padding-right: 30px;
  /* background-color: blue; */
}
.title1 {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: left;
  padding-left: 20px;
}
.list {
  /* border: 1px solid #005ea1; */
  text-align: left;
  height: calc(100% - 50px);
  /* overflow-y: auto; */
}
.shang {
  width: 100%;
  height: 90px;
  line-height: 90px;
  display: flex;
  border-bottom: 1px solid #005ea1;
}
.label {
  width: 100px;
  text-align: center;
  background-color: #1f335f;
  border-left: 1px solid #005ea1;
  border-right: 1px solid #005ea1;
}
.huan {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #005ea1;
  width: calc(100% - 100px);
}
.img {
  display: flex;
  /* flex-wrap: wrap; */
  margin-top: 20px;
  margin-left: 30px;
}
</style>
